
            import { init } from '@altipla/sentry-astro/vue'

            if (window.__sentry) {
              init({
                ...window.__sentry,
                integrations: integrations => {
                  integrations = integrations.filter(integration => integration.name !== 'Vue')
                  return integrations
                },
              })
            }
          